import React from "react";
import { cn } from "~/utils";

export const Loading = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const dotClassName = "w-0.5 h-0.5 bg-[currentcolor] rounded-full";
    return (
      <div ref={ref} className={cn("text-gray-400 flex gap-1", className)} {...props}>
        <div className={dotClassName} style={{ animation: "cycle 1.5s ease-in-out 0ms infinite" }} />
        <div className={dotClassName} style={{ animation: "cycle 1.5s ease-in-out 200ms infinite" }} />
        <div className={dotClassName} style={{ animation: "cycle 1.5s ease-in-out 400ms infinite" }} />
      </div>
    );
  },
);
