import { Prisma, type Episode } from "@prisma/client";
import * as Lucide from "lucide-react";
import { useEffect } from "react";
import Markdown from "react-markdown";
import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from "react-router";
import { Form, Link, useLoaderData, useRevalidator } from "react-router";
import rehypeExternalLinks from "rehype-external-links";
import { db } from "~/.server/db";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { Loading } from "~/components/ui/loading";
import { userFromMatches, useUser } from "~/with-user";
import { getRequestEpisode } from "./get.server";
import { episodeShortName, PREVIEW_EPISODE_KEY } from "./props";
import { publishPendingEpisodes } from "./publish.server";

function ContentFallback({ episode }: { episode: Episode }) {
  if (episode.status === "ok") {
    return <p className="text-sm">Sorry, there was no activity to summarize.</p>;
  }
}

function PreviewNote() {
  return (
    <p className="text-sm text-muted-foreground mt-6 bg-muted p-4 rounded-md">
      A preview is limited to three channels from one source.
    </p>
  );
}

function StatusLabel({ episode }: { episode: Episode }) {
  const { status } = episode;
  const statusColor = status === "error" ? "text-red-500" : "text-gray-400";
  const statusText =
    status === "error" ? `Failed with error: ${episode.error}` : (episode.statusMessage ?? "Preparing");
  return (
    <div className="flex items-end">
      <span className={statusColor}>{statusText}</span>
      {status !== "error" && <Loading className={`ml-0.5 pb-[6px] ${statusColor}`} />}
    </div>
  );
}

export function EpisodeContent({ episode }: { episode: Episode }) {
  const { episodeKey, status, speechStorageUrl, displayMarkdown } = episode;
  const revalidator = useRevalidator();
  const trackUpdates = status !== "ok";
  useEffect(() => {
    if (trackUpdates) {
      const source = new EventSource(`/${episodeKey}/updates`);
      source.onmessage = (_event) => {
        revalidator.revalidate();
      };
      return () => source.close();
    }
  }, [episodeKey, trackUpdates]);
  const speechUrl = speechStorageUrl ? `/${episodeKey}.mp3` : null;
  return (
    <>
      {status !== "ok" && <StatusLabel episode={episode} />}
      {speechUrl && (
        <audio className="w-full mb-6" controls>
          <source src={speechUrl} type="audio/mp3" />
          <a href={speechUrl}>Listen to this summary</a>
        </audio>
      )}
      {displayMarkdown ? (
        <Markdown className="summary" rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}>
          {displayMarkdown}
        </Markdown>
      ) : (
        <ContentFallback episode={episode} />
      )}
      {episodeKey === PREVIEW_EPISODE_KEY && status === "ok" && <PreviewNote />}
    </>
  );
}

// PAGE DEV ACTIONS

export const action = async (args: ActionFunctionArgs) => {
  const episode = await getRequestEpisode(args);
  const formData = await args.request.formData();
  const action = formData.get("action");
  const episodeData: Prisma.EpisodeUpdateInput = {
    scheduledAt: new Date(),
    status: "queued",
    speechStorageUrl: null,
  };
  if (action === "regenerate-summary" || action === "regenerate") {
    episodeData.displayMarkdown = null;
    episodeData.speechText = null;
  }
  await db.episode.update({
    where: {
      userId_episodeKey: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
      },
    },
    data: episodeData,
  });
  if (action === "regenerate-summary" || action === "regenerate") {
    const summaryData: Prisma.SourceSummaryUpdateInput = {
      rawSummary: Prisma.DbNull,
      displayMarkdown: null,
      speechText: null,
    };
    if (action === "regenerate") {
      summaryData.sourceContent = Prisma.DbNull;
    }
    await db.sourceSummary.updateMany({
      where: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
      },
      data: summaryData,
    });
  }

  await publishPendingEpisodes();

  return null;
};

function DevButtons({ episode }: { episode: Episode }) {
  const disabled = episode.status !== "ok" && episode.status !== "error";
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Lucide.Wrench className="size-4 text-gray-600 group-hover:text-gray-900 ml-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent asChild align="end">
        <Form method="post" className="flex flex-col">
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate" type="submit" disabled={disabled}>
              <Lucide.RefreshCcw />
              <span>Regenerate all</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-summary" type="submit" disabled={disabled}>
              <Lucide.Merge />
              <span>Regenerate summary</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-speech" type="submit" disabled={disabled}>
              <Lucide.Speech />
              <span>Regenerate speech</span>
            </button>
          </DropdownMenuItem>
        </Form>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

// PAGE LOADER

export async function loader(args: LoaderFunctionArgs) {
  const episode = await getRequestEpisode(args);
  const [nextEpisode, previousEpisode] = await Promise.all([
    db.episode.findFirst({
      where: {
        userId: episode.userId,
        scheduledAt: { gt: episode.scheduledAt },
        status: { not: "queued" },
        episodeKey: { not: PREVIEW_EPISODE_KEY },
      },
      orderBy: { scheduledAt: "asc" },
      select: { episodeKey: true },
    }),
    db.episode.findFirst({
      where: {
        userId: episode.userId,
        scheduledAt: { lt: episode.scheduledAt },
        status: { not: "queued" },
        episodeKey: { not: PREVIEW_EPISODE_KEY },
      },
      orderBy: { scheduledAt: "desc" },
      select: { episodeKey: true },
    }),
  ]);
  return { episode, nextEpisodeKey: nextEpisode?.episodeKey, previousEpisodeKey: previousEpisode?.episodeKey };
}

// PREV / NEXT

function NavLink({
  IconLeft,
  IconRight,
  label,
  episodeKey,
}: {
  IconLeft?: React.ComponentType<Lucide.LucideProps>;
  IconRight?: React.ComponentType<Lucide.LucideProps>;
  label: string;
  episodeKey?: string;
}) {
  if (episodeKey) {
    return (
      <Link
        to={`/${episodeKey}`}
        className="text-sm font-medium flex items-center text-gray-500 hover:text-gray-600 hover:bg-gray-100 rounded-full select-none"
      >
        {IconLeft && <IconLeft className="size-4 -mr-2" />}
        <span className="mx-3">{label}</span>
        {IconRight && <IconRight className="size-4 -ml-2" />}
      </Link>
    );
  }
  return (
    <span className="text-sm font-medium flex items-center text-gray-300 select-none">
      {IconLeft && <IconLeft className="size-4 -mr-2" />}
      <span className="mx-3">{label}</span>
      {IconRight && <IconRight className="size-4 -ml-2" />}
    </span>
  );
}

// PAGE

export default function EpisodePage() {
  const { episode, nextEpisodeKey, previousEpisodeKey } = useLoaderData<typeof loader>();
  const user = useUser();

  return (
    <Card className="content-w mb-6">
      <CardHeader className="relative pb-6">
        <CardTitle className="flex items-center">
          <span className="flex-grow">{episodeShortName(episode, user)}</span>
          <span className="text-base font-normal text-gray-500 flex items-center">
            <NavLink IconLeft={Lucide.ChevronLeft} label="Next" episodeKey={nextEpisodeKey} />
            <NavLink IconRight={Lucide.ChevronRight} label="Previous" episodeKey={previousEpisodeKey} />
            {user.devMode && <DevButtons episode={episode} />}
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <EpisodeContent episode={episode} />
      </CardContent>
    </Card>
  );
}

// PAGE META

export const meta: MetaFunction = ({ data, matches }) => {
  const { episode } = data as ReturnType<typeof useLoaderData<typeof loader>>;
  const user = userFromMatches(matches);
  return [{ title: `${episodeShortName(episode, user)} · Sumcast` }];
};
